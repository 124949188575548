import { Component, OnInit } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-viewdetails',
  templateUrl: './viewdetails.component.html',
  styleUrls: ['./viewdetails.component.scss']
})
export class ViewdetailsComponent implements OnInit {


  userid: string;
  datas: any;
  public model: Adduser;

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router,private sanitizer:DomSanitizer) {
    this.model = new Adduser();
    this.route.params.subscribe(params => {
      this.userid = params['id']; // (+) converts string 'id' to a number
      console.log('this id: ' + this.userid);
      this.httpClient.post(' https://dcsmaju.com.my/api/authentication/userid', { userid: this.userid }).subscribe((data: any) => {
        console.log(data.data);
        this.model = data.data;

      });
    });
  }
  downloadQRCode() {
    const downloadLink = document.createElement('a');
    downloadLink.href = this.model.qrcode;
    downloadLink.download =  `${this.model.Name}QRcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  

  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  ngOnInit() {
    this.syncdata();
  }
  syncdata() {
    console.log("data in");

  }


}

class Adduser {
  user_id: string;
  Name: string;
  Designation: string;
  Level: string;
  Phone_number: string;
  Email_id: string;
  Password: string;
  Add_by: string;
  qrcode:string
}