
import { Component, OnInit } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgotpswd',
  templateUrl: './forgotpswd.component.html',
  styleUrls: ['./forgotpswd.component.scss']
})
export class ForgotpswdComponent implements OnInit {
  datas: any;

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private toastr :ToastrService) {

  }

  ngOnInit() {
  }

  sub(email) {
    if (email !== '') {
      this.http.post(' https://dcsmaju.com.my/api/authentication/checkuser', { "Email_id": email }).subscribe(data => {
        this.datas = data;
        console.log(this.datas);
        if (this.datas.code !== 200) {
          this.toastr.error(this.datas.message);
        } else {
          this.toastr.success("The password Will send to your are Mail-ID");
          this.router.navigate(['']);
        }
      });

    } else {
      this.toastr.error("Enter the Login-id");
    }
  }

}
