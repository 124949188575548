import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-attendence-map',
  templateUrl: './attendence-map.component.html',
  styleUrls: ['./attendence-map.component.scss']
})
export class AttendenceMapComponent {

  @ViewChild('mapContainer', { static: false })
  gmap!: ElementRef;
  map!: google.maps.Map;
  datas: any;
  lat: number;
  lng: number;
  constructor(private activeRoute: ActivatedRoute, private http: HttpClient) {
    this.activeRoute.params.subscribe(params => {
      const dataString = params['id'];
      const data = JSON.parse(dataString);
      console.log('Received data:', data);
      this.getCurrentLocation(data);
    });
  }
  
  ngOnInit(): void {
  
  }
  
  // getCurrentLocation(data: any): void {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       const currentLat = position.coords.latitude;
  //       const currentLon = position.coords.longitude;
  //       console.log("Current Latitude:", currentLat, "Current Longitude:", currentLon);
  //       console.log("data", data);
  
  //       const currentCoordinates = new google.maps.LatLng(currentLat, currentLon);
  //       const checkInCoordinates = new google.maps.LatLng(data.checkInlat as number, data.checkInlon as number);
  //       const checkOutCoordinates = new google.maps.LatLng(data.checkOutlat as number, data.checkOutlon as number);
  
  //       const mapOptions: google.maps.MapOptions = {
  //         center: currentCoordinates,
  //         zoom: 16,
  //       };
  
  //       // Move map initialization inside the callback
  //       this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
  
  //       // Add markers for check-in and check-out locations
  //       const checkInMarker = new google.maps.Marker({
  //         position: checkInCoordinates,
  //         map: this.map,
  //         title: 'Check-In Location'
  //       });
  
  //       const checkOutMarker = new google.maps.Marker({
  //         position: checkOutCoordinates,
  //         map: this.map,
  //         title: 'Check-Out Location'
  //       });
  
  //       // Add marker for current location
  //       const currentMarker = new google.maps.Marker({
  //         position: currentCoordinates,
  //         map: this.map,
  //         title: 'Current Location'
  //       });
  
  //       console.log('Current Latitude:', currentLat);
  //       console.log('Current Longitude:', currentLon);
  //     }, (error) => {
  //       console.error('Error getting current location:', error);
  //     });
  //   } else {
  //     console.error('Geolocation is not supported by this browser.');
  //   }
  // }
  getCurrentLocation(data:any): void {
    this.http.get('https://ipapi.co/json/').subscribe(
      (location: any) => {
        const currentLat = location.latitude;
        const currentLon = location.longitude;
        console.log('Current Latitude:', currentLat);
        console.log('Current Longitude:', currentLon);
        this.initializeMap(currentLat, currentLon,data);
      },
      (error) => {
        console.error('Error getting current location:', error);
      }
    );
  }

  initializeMap(lat: number, lon: number,data:any): void {
 

    const currentCoordinates = new google.maps.LatLng(lat, lon);
    const checkInCoordinates = new google.maps.LatLng(data.checkInlat, data.checkInlon);
    const checkOutCoordinates = new google.maps.LatLng(data.checkOutlat, data.checkOutlon);

    const mapOptions: google.maps.MapOptions = {
      center: currentCoordinates,
      zoom: 16,
    };

    this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, mapOptions);

    const checkInMarker = new google.maps.Marker({
      position: checkInCoordinates,
      map: this.map,
      title: 'Check-In Location'
    });

    const checkOutMarker = new google.maps.Marker({
      position: checkOutCoordinates,
      map: this.map,
      title: 'Check-Out Location'
    });

    const currentMarker = new google.maps.Marker({
      position: currentCoordinates,
      map: this.map,
      title: 'Current Location'
    });
  }

}
