import { NgModule, Pipe, Component, OnInit, Attribute, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import MeasureTool from 'measuretool-googlemaps-v3/dist/gmaps-measuretool.umd.js';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-addclientsite',
  templateUrl: './addclientsite.component.html',
  styleUrls: ['./addclientsite.component.scss']
})
export class AddclientsiteComponent implements OnInit {
  public second_formData: any = {
    full_address: '',
    street: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    latitude: '',
    longitude: '',
    area: '',
    googleMapLocation: '',
  };
  cliid: any;
  client_id: string;
  datas: any;
  addsite: addsite;
  companylist: any;
  ucodenumber: number;
  ucodetext: string;
  dataAvailable:boolean = false;
  @ViewChild('mapContainer', { static: false })
  gmap!: ElementRef;
  map!: google.maps.Map;
  inverted = false;
  measureTool: any;
  lat: Number;
  lng: Number;
  mapList: any[];
  loactionsdatas: any;
  totalarea: any = 0;
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.client_id = params['id']; // (+) converts string 'id' to a number
      console.log('this id: ' + this.client_id);
    });
    this.http.post(' https://dcsmaju.com.my/api/company/companylists', { "id": 0 }).subscribe((data: any) => {
      this.companylist = data.data;
      console.log(this.companylist);
    });
    this.getCurrentLocation();
  }

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private _formBuilder: FormBuilder,private toastr : ToastrService) {
    this.addsite = new addsite();

    this.http.post(' https://dcsmaju.com.my/api/advance/fetchunit_number1', { "id": 0 }).subscribe((data: any) => {
      this.ucodenumber = +data.data[0].max;
      this.ucodenumber = this.ucodenumber + 1;
      this.ucodetext = 'U';
      console.log(this.ucodenumber);
      console.log(this.ucodetext);
      this.addsite.sitelogin = this.ucodetext + this.ucodenumber;
    });
  }
  secondFormGroup = this._formBuilder.group({
    company_name:new FormControl(''),
    address: new FormGroup({
      locality: new FormControl(''),
      fullAddress: new FormControl(''),
      city: new FormControl(''),
      street: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
      googleMapLocation: new FormControl(''),
      postalCode: new FormControl(''),
      latitude: new FormControl(''),
      longitude: new FormControl(''),
      area: new FormControl(''),
    }),
  });

  addapi() {
    if (this.secondFormGroup.invalid) {
      this.toastr.error('Please fill out all required fields.');
      return;
    }
    if(!this.secondFormGroup.value.company_name){
      this.toastr.error('Please enter the Site name.');
    }
    this.addsite.client_id = "" + 0;
    this.addsite.status = "enable";
    console.log(this.addsite)
    let data = {
      ...this.addsite,
      ...this.secondFormGroup.value.address,
      ...this.loactionsdatas,
      company_name:this.secondFormGroup.value.company_name
    }
    console.log(":data", data);

    this.http.post('https://dcsmaju.com.my/api/client/newclientsite', data).subscribe((data: any) => {
      if (data.code == 200) {
        this.datas = data.data;
        console.log(this.datas);
        this.router.navigate(['main/clientsite'])
        this.toastr.success("Site Created Successfully");
      }else{
        this.toastr.error(data.message);
      }
      
    });
  }




  getCurrentLocation() {
    if (navigator.geolocation) {
     
      navigator.geolocation.getCurrentPosition((position) => {
        this.dataAvailable = true;
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        console.log("this.lat", this.lat, "this.lng", this.lng);

        const coordinates = new google.maps.LatLng(this.lat as number, this.lng as number);


        const mapOptions: google.maps.MapOptions = {
          center: coordinates,
          zoom: 16,
        };

        // Move map initialization inside the callback
        this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);

        // Add marker after map is initialized
        const marker = new google.maps.Marker({
          position: coordinates,
          map: this.map,
        });

        this.mapMeasureTool();
        console.log('Latitude:', this.lat);
        console.log('Longitude:', this.lng);
      }, (error) => {
        this.dataAvailable = false;
        console.error('Error getting current location:', error);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }


  mapMeasureTool() {
    
    this.measureTool = new MeasureTool(this.map, {
      showSegmentLength: false,
      contextMenu: false,
      unit: MeasureTool.UnitTypeId.IMPERIAL, // or just use 'imperial'
    });
    console.log("this.measureTool", this.measureTool);

    this.measureTool.addListener('measure_end', (measurement: any) => {
      this.loactionsdatas = measurement

     
      this.totalarea = this.loactionsdatas.result.area
        console.log('Measurement:', measurement);
      console.log("area", this.totalarea);

    });
  }



  onStart() {
    this.measureTool.start();
  }

  onEnd() {
    this.measureTool.end();
  }

  onInvert() {
    this.inverted = !this.inverted;
    this.measureTool.setOption('invertColor', this.inverted);
  }
  clearGeoLocation() {
    this.mapList = [];
    this.secondFormGroup.reset();
    this.second_formData = {};
    console.log(this.second_formData);
    console.log(this.secondFormGroup.reset());
  }

  /* function setMapLocation()
  @param e;
  @use for select one location from the list dropdown and update the Address details by using place_id
  */
  async setMapLocation(e: any) {
    if (!e) {
      return;
    }
    try {
      console.log(this.secondFormGroup.value.address?.googleMapLocation);

      this.googlemapselectList(
        this.secondFormGroup.value.address?.googleMapLocation
      )
        .subscribe((result: any) => {
          if (result) {
            
            const map = result; // Assuming you want the first result

            const { address_components, geometry } = map?.result;
            this.second_formData.full_address = map.result.formatted_address;
            if (geometry && geometry.location) {
              this.dataAvailable = true;
              this.second_formData.latitude = geometry.location.lat;
              this.second_formData.longitude = geometry.location.lng;
              const coordinates = new google.maps.LatLng(this.second_formData.latitude as number, this.second_formData.longitude as number);


              const mapOptions: google.maps.MapOptions = {
                center: coordinates,
                zoom: 19,
              };

              // Move map initialization inside the callback
              this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);

              // Add marker after map is initialized
              const marker = new google.maps.Marker({
                position: coordinates,
                map: this.map,
              });

              this.mapMeasureTool();
            }

            if (address_components && Array.isArray(address_components)) {
              // Iterate through address components
              address_components.forEach((value: any) => {
                if (value.types.indexOf('street') >= 0) {
                  this.second_formData.street = value.long_name;
                }
                if (value.types.indexOf('country') >= 0) {
                  this.second_formData.country = value.long_name;
                }
                if (value.types.indexOf('postal_code') >= 0) {
                  this.second_formData.zipCode = value.long_name;
                }
                if (value.types.indexOf('administrative_area_level_1') >= 0) {
                  this.second_formData.state = value.long_name;
                }
                if (value.types.indexOf('sublocality_level_1') >= 0) {
                  this.second_formData.area = value.long_name;
                }
                if (value.types.indexOf('locality') >= 0) {
                  this.second_formData.city = value.long_name;
                }
                if (value.types.indexOf('route') >= 0) {
                  this.second_formData.street += value.long_name + ', ';
                }
              });
            } else {
              let addressParts = address_components.split(',');

              const inputString = address_components;

              const postalCodeRegex = /\b\d{6}\b/;

              const postalCodeMatch = inputString.match(postalCodeRegex);

              if (postalCodeMatch) {
                const postalCode = postalCodeMatch[0];
                console.log('Postal Code:', postalCode);
                this.second_formData.zipCode = postalCode;
              } else {
                console.log('Postal code not found in the input string.');
              }
              console.log('addressParts', addressParts);

              if (addressParts.length >= 1) {
                this.second_formData.street = addressParts[1].trim();
              }
              if (addressParts.length >= 2) {
                this.second_formData.city = addressParts[2].trim();
              }

              if (addressParts.length >= 3) {
                let stateAndZipCode = addressParts[3].trim().split(' ');
                this.second_formData.state = stateAndZipCode[0];
              }
              // if (addressParts.length >= 4) {
              //   this.second_formData.zipCode = addressParts[4].trim();
              // }
              if (addressParts.length >= 5) {
                this.second_formData.country = addressParts[5].trim();
              }
            }
          }
          this.secondFormGroup.patchValue({
            address: {
              // locality: this.second_formData.locality,
              fullAddress: this.second_formData.full_address,
              city: this.second_formData.city,
              state: this.second_formData.state,
              country: this.second_formData.country,
              latitude: this.second_formData.latitude,
              longitude: this.second_formData.longitude,
              postalCode: this.second_formData.zipCode,
              street: this.second_formData.street,
              area: this.second_formData.area,
            },
          });
          console.log(this.secondFormGroup.value);
        });
    } catch (err) {
      console.log(err);
    } finally {
      this.mapList = [];
    }
  }

  async getMapLocation(e: any) {
    if (e.target.value.length >= 0) {
      console.log('e.target.value', e.target.value);

      this.googlemapList(e.target.value).subscribe((res: any) => {
        console.log('res', res.results);

        this.mapList = res.results;

        // res.results.forEach((result: any) => {
        //   if (result.place_id) {
        //     this.mapList.push(result.place_id);
        //   }
        // });
      });
    }
    // console.log("this.mapList",this.mapList);
  }
  googlemapList(query: any) {
    return this.http.post('https://dcsmaju.com.my/api/search_places', { query: query })
  }
  googlemapselectList(query: any) {
    return this.http.post('https://dcsmaju.com.my/api/select_places', { query: query })
  }
}



class addsite {
  client_id: string;
  title: string;
  description: string;
  address: string;
  billing_address: string;
  sitelogin: string;
  sitepassword: string;
  company_name: string;
  contactperson1: string;
  contactnumber1: string;
  contactemail1: string;
  contactperson2: string;
  contactnumber2: string;
  contactemail2: string;
  contactperson3: string;
  contactnumber3: string;
  contactemail3: string;
  status: string;
}
