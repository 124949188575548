import { NgModule, Pipe, Component, OnInit, Attribute, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import MeasureTool from 'measuretool-googlemaps-v3/dist/gmaps-measuretool.umd.js';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-viewsite',
  templateUrl: './viewsite.component.html',
  styleUrls: ['./viewsite.component.scss']
})
export class ViewsiteComponent implements OnInit {

  companylist: any;
  cliid: any;
  client_id: any;
  addsite: addsite;
  datas: any;
  public second_formData: any = {
    full_address: '',
    street: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    latitude: '',
    longitude: '',
    area: '',
    googleMapLocation: '',
  };

  ucodenumber: number;
  ucodetext: string;
  @ViewChild('mapContainer', { static: false })
  gmap!: ElementRef;
  map!: google.maps.Map;
  inverted = false;
  measureTool: any;
  lat: Number;
  lng: Number;
  mapList: any[];
  loactionsdatas: any;
  totalarea: any = 0;
  polylines: any[];
  markers: any;
  datasall: any;
  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private _formBuilder: FormBuilder,private toastr:ToastrService) {
    this.addsite = new addsite();


    this.route.params.subscribe(params => {
      this.client_id = params['id']; // (+) converts string 'id' to a number
      console.log('this id: ' + this.client_id);
      this.http.post(' https://dcsmaju.com.my/api/client/fetchsite', { "id": this.client_id }).subscribe((data: any) => {
        console.log('data', data)
        this.addsite = data.data;
        console.log('addsite', this.addsite);
        this.mapuserlist(this.client_id)

        const coordinates = new google.maps.LatLng(this.addsite.result.points[0].lat, this.addsite.result.points[0].lng); // Set the initial coordinates using the first point in the array

        const mapOptions: google.maps.MapOptions = {
          center: coordinates,
          zoom: 19,
        };

        // Move map initialization inside the callback
        this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);

        // Add marker for each point
        this.addsite.result.points.forEach(point => {
          new google.maps.Marker({
            position: { lat: point.lat, lng: point.lng },
            map: this.map,
          });
        });

        for (let i = 0; i < this.addsite.result.points.length - 1; i++) {
          const point1 = this.addsite.result.points[i];
          const point2 = this.addsite.result.points[i + 1];
          new google.maps.Polyline({
            path: [point1, point2],
            geodesic: true,
            strokeColor: "#00000",
            strokeOpacity: 1.0,
            strokeWeight: 5,
            map: this.map
          });
        }
        this.mapMeasureTool();
      });
      this.http.post(' https://dcsmaju.com.my/api/company/companylists', { "id": 0 }).subscribe((data: any) => {
        this.companylist = data.data;
        console.log(this.companylist);
      });
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.client_id = params['id']; // (+) converts string 'id' to a number
      console.log('this id: ' + this.client_id);
    });
  }
  mapuserlist(data: any) {
    this.http.post('https://dcsmaju.com.my/api/mapTracking/addmapuserlist', { Map_id: data }, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).subscribe((response: any) => {
      if (response.status === 401) {
        console.error("Unauthorized: ", response.message);
        // Handle unauthorized access error here (e.g., redirect to login)
      } else {
        this.datasall = response;
        console.log("this.client_id", this.datasall);
  
        // Assuming the response contains data array
        if (this.datasall.data && this.datasall.data.length > 0) {
          this.datas = this.datasall.data[0].data;
          console.log("345346356", this.datasall.data[0].data);
        } else {
          console.error("No data found in response.");
          // Handle no data found scenario
        }
      }
    }, error => {
      console.error("An error occurred: ", error);
      // Handle other errors here
    });
  }
  
  secondFormGroup = this._formBuilder.group({
    company_name: new FormControl(''),
    address: new FormGroup({
      locality: new FormControl(''),
      fullAddress: new FormControl(''),
      city: new FormControl(''),
      street: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
      googleMapLocation: new FormControl(''),
      postalCode: new FormControl(''),
      latitude: new FormControl(''),
      longitude: new FormControl(''),
      area: new FormControl(''),
    }),
  });

  // addapi() {
  //   this.addsite.client_id = "" + 0;
  //   this.addsite.status = "enable";
  //   console.log(this.addsite)
  //   let data = {
  //     ...this.addsite,
  //     ...this.secondFormGroup.value.address,
  //     ...this.loactionsdatas,
  //     company_name:this.secondFormGroup.value.company_name
  //   }
  //   console.log(":data", data);

  //   this.http.post('https://dcsmaju.com.my/api/client/newclientsite', data).subscribe((data: any) => {
  //     this.datas = data.data;
  //     console.log(this.datas);
  //     this.router.navigate(['main/clientsite'])
  //   });
  // }




  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        console.log("this.lat", this.lat, "this.lng", this.lng);

        const coordinates = new google.maps.LatLng(this.lat as number, this.lng as number);


        const mapOptions: google.maps.MapOptions = {
          center: coordinates,
          zoom: 16,
        };

        // Move map initialization inside the callback
        this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);

        // Add marker after map is initialized
        const marker = new google.maps.Marker({
          position: coordinates,
          map: this.map,
        });

        this.mapMeasureTool();
        console.log('Latitude:', this.lat);
        console.log('Longitude:', this.lng);
      }, (error) => {
        console.error('Error getting current location:', error);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }


  mapMeasureTool() {
    this.measureTool = new MeasureTool(this.map, {
      showSegmentLength: false,
      contextMenu: false,
      unit: MeasureTool.UnitTypeId.IMPERIAL, // or just use 'imperial'
    });
    console.log("this.measureTool", this.measureTool);

    this.measureTool.addListener('measure_end', (measurement: any) => {
      this.loactionsdatas = measurement


      this.totalarea = this.loactionsdatas.result.area
      console.log('Measurement:', measurement);
      console.log("area", this.totalarea);

    });
  }



  // Function to remove existing polylines and markers
  removePolylinesAndMarkers() {
    // Remove existing polylines
    if (this.polylines) {
      this.polylines.forEach(polyline => {
        polyline.setMap(null);
      });
    }

    // Remove existing markers
    if (this.markers) {
      this.markers.forEach(marker => {
        marker.setMap(null);
      });
    }
  }

  // Function to create polylines and markers
  createPolylinesAndMarkers(points) {
    // Create markers
    this.markers = points.map(point => {
      return new google.maps.Marker({
        position: { lat: point.lat, lng: point.lng },
        map: this.map
      });
    });

    // Create polylines
    this.polylines = [];
    for (let i = 0; i < points.length - 1; i++) {
      const point1 = points[i];
      const point2 = points[i + 1];
      const polyline = new google.maps.Polyline({
        path: [point1, point2],
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
        map: this.map
      });
      this.polylines.push(polyline);
    }
  }

  // onStart function
  onStart() {
    // Remove existing polylines and markers
    this.removePolylinesAndMarkers();

    // Start measure tool
    this.measureTool.start();
  }

  // Click event handler
  handleClick() {
    // Remove existing polylines and markers
    this.removePolylinesAndMarkers();

    // Create polylines and markers using points from this.addsite.points
    this.createPolylinesAndMarkers(this.addsite.result.points);
  }

  onEnd() {
    this.measureTool.end();
  }

  onInvert() {
    this.inverted = !this.inverted;
    this.measureTool.setOption('invertColor', this.inverted);
  }
  clearGeoLocation() {
    this.mapList = [];
    this.secondFormGroup.reset();
    this.second_formData = {};
    console.log(this.second_formData);
    console.log(this.secondFormGroup.reset());
  }

  /* function setMapLocation()
  @param e;
  @use for select one location from the list dropdown and update the Address details by using place_id
  */
  async setMapLocation(e: any) {
    if (!e) {
      return;
    }
    try {
      console.log(this.secondFormGroup.value.address?.googleMapLocation);

      this.googlemapselectList(
        this.secondFormGroup.value.address?.googleMapLocation
      )
        .subscribe((result: any) => {
          if (result) {
            const map = result; // Assuming you want the first result

            const { address_components, geometry } = map?.result;
            this.second_formData.full_address = map.result.formatted_address;
            if (geometry && geometry.location) {
              this.second_formData.latitude = geometry.location.lat;
              this.second_formData.longitude = geometry.location.lng;
              const coordinates = new google.maps.LatLng(this.second_formData.latitude as number, this.second_formData.longitude as number);


              const mapOptions: google.maps.MapOptions = {
                center: coordinates,
                zoom: 19,
              };

              // Move map initialization inside the callback
              this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);

              // Add marker after map is initialized
              const marker = new google.maps.Marker({
                position: coordinates,
                map: this.map,
              });

              this.mapMeasureTool();
            }

            if (address_components && Array.isArray(address_components)) {
              // Iterate through address components
              address_components.forEach((value: any) => {
                if (value.types.indexOf('street') >= 0) {
                  this.second_formData.street = value.long_name;
                }
                if (value.types.indexOf('country') >= 0) {
                  this.second_formData.country = value.long_name;
                }
                if (value.types.indexOf('postal_code') >= 0) {
                  this.second_formData.zipCode = value.long_name;
                }
                if (value.types.indexOf('administrative_area_level_1') >= 0) {
                  this.second_formData.state = value.long_name;
                }
                if (value.types.indexOf('sublocality_level_1') >= 0) {
                  this.second_formData.area = value.long_name;
                }
                if (value.types.indexOf('locality') >= 0) {
                  this.second_formData.city = value.long_name;
                }
                if (value.types.indexOf('route') >= 0) {
                  this.second_formData.street += value.long_name + ', ';
                }
              });
            } else {
              let addressParts = address_components.split(',');

              const inputString = address_components;

              const postalCodeRegex = /\b\d{6}\b/;

              const postalCodeMatch = inputString.match(postalCodeRegex);

              if (postalCodeMatch) {
                const postalCode = postalCodeMatch[0];
                console.log('Postal Code:', postalCode);
                this.second_formData.zipCode = postalCode;
              } else {
                console.log('Postal code not found in the input string.');
              }
              console.log('addressParts', addressParts);

              if (addressParts.length >= 1) {
                this.second_formData.street = addressParts[1].trim();
              }
              if (addressParts.length >= 2) {
                this.second_formData.city = addressParts[2].trim();
              }

              if (addressParts.length >= 3) {
                let stateAndZipCode = addressParts[3].trim().split(' ');
                this.second_formData.state = stateAndZipCode[0];
              }
              // if (addressParts.length >= 4) {
              //   this.second_formData.zipCode = addressParts[4].trim();
              // }
              if (addressParts.length >= 5) {
                this.second_formData.country = addressParts[5].trim();
              }
            }
          }
          this.secondFormGroup.patchValue({
            company_name: this.second_formData.company_name,
            address: {
              // locality: this.second_formData.locality,
              fullAddress: this.second_formData.full_address,
              city: this.second_formData.city,
              state: this.second_formData.state,
              country: this.second_formData.country,
              latitude: this.second_formData.latitude,
              longitude: this.second_formData.longitude,
              postalCode: this.second_formData.zipCode,
              street: this.second_formData.street,
              area: this.second_formData.area,
            },
          });
          console.log(this.secondFormGroup.value);
        });
    } catch (err) {
      console.log(err);
    } finally {
      this.mapList = [];
    }
  }

  async getMapLocation(e: any) {
    if (e.target.value.length >= 0) {
      console.log('e.target.value', e.target.value);

      this.googlemapList(e.target.value).subscribe((res: any) => {
        console.log('res', res.results);

        this.mapList = res.results;

        // res.results.forEach((result: any) => {
        //   if (result.place_id) {
        //     this.mapList.push(result.place_id);
        //   }
        // });
      });
    }
    // console.log("this.mapList",this.mapList);
  }
  googlemapList(query: any) {
    return this.http.post('https://dcsmaju.com.my/api/search_places', { query: query })
  }
  googlemapselectList(query: any) {
    return this.http.post('https://dcsmaju.com.my/api/select_places', { query: query })
  }
  // open(event: any, data: any) {
  //   console.log("this.data", data)
  //   let updateData = {
  //     Map_id:data.Map_id,
  //     Emp_id: data.Emp_id,
  //     status: "close"
  //   }
  //   this.http.post(' https://dcsmaju.com.my/api/updateuserstatus', { ...updateData }).subscribe((data: any) => {
  //     this.companylist = data.data;
  //     this.mapuserlist(this.client_id);
  //     this.toastr.success("Employees removed successfully");
     
  //     window.location.reload(); 
  //   });

  // }


  open(event: any, data: any) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let updateData = {
          Map_id:data.Map_id,
          Emp_id: data.Emp_id,
          status: "close"
        }
        this.http.post(' https://dcsmaju.com.my/api/updateuserstatus', { ...updateData }).subscribe((data: any) => {
          this.companylist = data.data;
          this.mapuserlist(this.client_id);
          Swal.fire(
            'Remove!',
            'Employee has been removed.',
            'success'
          )
          this.toastr.success("Employees removed successfully");
         
          this.ngOnInit();
        });
       
       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Employee is safe :)',
          'error'
        )
      }
    })
  }
}
class addsite {
  id: any;
  client_id: string;
  title: string;
  description: string;
  address: string;
  billing_address: string;
  sitelogin: string;
  sitepassword: string;
  company_name: string;
  contactperson1: string;
  contactnumber1: string;
  contactemail1: string;
  contactperson2: string;
  contactnumber2: string;
  contactemail2: string;
  contactperson3: string;
  contactnumber3: string;
  contactemail3: string;
  result: {
    points: Array<any>;
  };
  status: string;
}
