import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginPageComponent {
  user_name: string;
  showbutton: boolean;

  datas: any;

  adduser: Adduser;
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private http: HttpClient, private route: ActivatedRoute, private router: Router,
  private toastr: ToastrService) {
    this.adduser = new Adduser();
    this.showbutton = true;
  }


  addapi(Email_id: string, password: string) {
    if (Email_id !== '' && password !== '') {
      this.showbutton = false;
      console.log(this.adduser);
  
      this.adduser.Phone_number = Email_id;
      this.adduser.password = password;
  
      this.http.post('https://dcsmaju.com.my/api/authentication/bsslogin', this.adduser).subscribe(
        (data: any) => {
          this.datas = data;
          console.log(this.datas);
  
          if (this.datas.code !== 200) {
            this.toastr.error(this.datas.data);
            this.showbutton = true;
          } else {
            localStorage.setItem('Name', this.datas.data.Name);
            localStorage.setItem('user_id', this.datas.data._id);
            localStorage.setItem('token', this.datas.data.token);
            localStorage.setItem('Empolyee_id', this.datas.data.Empolyee_id);
            localStorage.setItem('usertype', this.datas.data.Designation);
  
            this.http.post('https://dcsmaju.com.my/api/notification/notificationcount', { "user_id": this.datas.data._id }).subscribe(
              (notificationData: any) => {
                const Count = notificationData.data.count;
                localStorage.setItem('notificount', Count);
                this.toastr.success("Login Successfully");
                this.router.navigate(['mainpage']);
              },
              (notificationError: any) => {
                console.error('Notification Count Error:', notificationError);
                this.toastr.error('Failed to retrieve notification count.');
                this.showbutton = true;
              }
            );
          }
        },
        (err: any) => {
          console.error('Login Error:', err);
          this.toastr.error('Login failed. Please Check Login-id and Password.');
          this.showbutton = true;
        }
      );
    } else {
      this.toastr.error('Enter the Login-id and Password');
      this.showbutton = true;
    }
  }
  
  forgot() {
    this.router.navigate(['forgotpassword']);
  }






  saveInLocal(key, val): void {
    console.log('recieved= key:' + key + 'value:' + val);
    this.storage.set(key, val);
  }
  getFromLocal(key): any {
    console.log('recieved= key:' + key);
    return this.storage.get(key);
  }
public togglePassword:boolean = false;
  passwordToggle() {
  this.togglePassword = this.togglePassword?false:true;
  }
}

// class Adduser {
//   Phone_number: string = "9360028667";
//   password: string = "Password@123";
// }

class Adduser {
  Phone_number: string;
  password: string;
  Email_id: string;
}