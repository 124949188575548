import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { ChangeDetectorRef } from '@angular/core';
import { interval, Subscription } from 'rxjs';

interface Point {
  lat: number;
  lng: number;
  emp_name: string;
}

@Component({
  selector: 'app-allemployees-tracking',
  templateUrl: './allemployees-tracking.component.html',
  styleUrls: ['./allemployees-tracking.component.css']
})
export class AllemployeesTrackingComponent implements OnInit, OnDestroy {
  zoom: number = 4;
  currentPos: Point = { lat: 13.0656, lng: 80.2431, emp_name: '' }; // Default position
  points: Point[] = [];
  site_id: any = "";
  datasss: any;
  private updateSubscription: Subscription;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private cref: ChangeDetectorRef,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.http.post('https://dcsmaju.com.my/api/client/sitelist', {}).subscribe((data: any) => {
      this.datasss = data?.data[0];
      console.log(this.datasss.data);
    });

    this.updateSubscription = interval(2000).subscribe(() => this.livemaptracting());
  }

  ngOnDestroy() {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  livemaptracting() {
    let data = 'https://dcsmaju.com.my/api/lastlocation';
    if (this.site_id) {
      data = `https://dcsmaju.com.my/api/lastlocation?site_id=${this.site_id}`;
    }
    this.http.get(data).subscribe(
      (response: any) => {
        if (response && response.data) {
          console.log('API Response:', response);
          this.points = response.data.map((item: any) => ({
            lat: parseFloat(item.lat),
            lng: parseFloat(item.lng),
            emp_name: item.emp_name
          }));
          console.log("this.points", this.points);
        } else {
          console.error('Response does not contain expected data:', response);
        }
      },
      (error: any) => {
        console.error('HTTP GET request failed:', error);
      }
    );
  }


refresh() {
  this.site_id = "";
  this.livemaptracting()
}

saveInLocal(key, val): void {
  console.log('recieved= key:' + key + 'value:' + val);
  this.storage.set(key, val);
}
getFromLocal(key): any {
  console.log('recieved= key:' + key);
  return this.storage.get(key);
}
































}

// just an interface for type safety.
interface point {
lat: number;
lng: number;
}
