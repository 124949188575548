import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editcontract',
  templateUrl: './editcontract.component.html',
  styleUrls: ['./editcontract.component.scss']
})
export class EditcontractComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
