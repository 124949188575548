import { Component, OnInit ,ViewEncapsulation,Inject} from '@angular/core';
import { MouseEvent } from '@agm/core';
import { HttpClientModule ,HttpClient} from '@angular/common/http';
import { ActivatedRoute ,Router} from '@angular/router';
import {LOCAL_STORAGE,  WebStorageService } from 'angular-webstorage-service';
import { Pipe, PipeTransform } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
 
@Component({
  selector: 'app-employeetracking',
  templateUrl: './employeetracking.component.html',
  styleUrls: ['./employeetracking.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class EmployeetrackingComponent implements OnInit {
  datas: any[] = [];
  filteredData: any[] = [];
  paginatedData: any[] = [];
  totalItems = 0;
  advancedPage = 1;
  pageSize = 10;
  searchText = '';

    constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,private http: HttpClient ,private activeRoute: ActivatedRoute, private router: Router) {
 
    }




    ngOnInit() {  
      this.getemployeeDatas();
    }
  
    getemployeeDatas() {
      this.http.post('https://dcsmaju.com.my/api/authentication/userlist', {}).subscribe((data: any) => {
        this.datas = data.data[0].data;  
        this.totalItems = data.data[0].pagination[0].totalItems; 
        this.filterData();
      }); 
    }
  
 
    filterData() {
      this.filteredData = this.datas.filter(data => 
        !this.searchText || 
        (data.Name && data.Name.toLowerCase().includes(this.searchText.toLowerCase())) || 
        (data.Empolyee_id && data.Empolyee_id.toLowerCase().includes(this.searchText.toLowerCase()))
      );
      this.totalItems = this.filteredData.length;
      this.paginate();
    }
    
    paginate() {
      const startIndex = (this.advancedPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      this.paginatedData = this.filteredData.slice(startIndex, endIndex);
    }
  
    search() {
      this.advancedPage = 1;
      this.filterData();
    }

view(data) {
  console.log(data.id)
  localStorage.setItem('employeetrackdetail',data);
  console.log("JSON.stringify(data)",JSON.stringify(data));
  this.router.navigate(['main/employeetrackinghistory/',{id:JSON.stringify(data)}]) 
}



delete(data){
  Swal.fire({
    title: 'Are you sure want to remove?',
    text: 'You will not be able to recover this file!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.http.post(' https://dcsmaju.com.my/api/employee_tracking/deleteEmployeeTracking', {'id': data.id}).subscribe(( data: any) => {
        this.ngOnInit();
    });
      Swal.fire(
        'Deleted!',
        'Your data has been deleted.',
        'success'
      )
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }
  })
}
saveInLocal(key, val): void {
  console.log('recieved= key:' + key + 'value:' + val);
  this.storage.set(key, val);
 }
 getFromLocal(key): any {
  console.log('recieved= key:' + key);
  return this.storage.get(key);
 }

 

}
// just an interface for type safety.
interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}


// constructor(private http: HttpClient ,private route: ActivatedRoute, private router: Router) {
  // this.http.post(' https://dcsmaju.com.my/api/authentication/Trackinglist',{"client_ID":"1"}).subscribe((data:any) => {
  //   this.datas = data.data;
    // data.data.forEach(element => {
    //   let d = {
    //   lat: +element.Lat,
    //   lng: +element.Long,
    //   Name: element.Name,
    //   draggable: true

    //   };
    //   this.markers.push(d);
    //   console.log(this.markers)  
    // });
    
  // }); 