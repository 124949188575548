import { Component, OnInit } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as XLSX from 'xlsx';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-viewuser',
  templateUrl: './viewuser.component.html',
  styleUrls: ['./viewuser.component.scss']
})
export class ViewuserComponent {
  titles = [];
  datas: any[] = [];
  filteredData: any[] = [];
  paginatedData: any[] = [];
  totalItems = 0;
  advancedPage = 1;
  pageSize = 10;
  searchText = '';
  loading = false;
  userid: string;
  moral: string;
  model: form1model;
  datasall: any;
  qrCodeData: string;
  deleteLoading: boolean;
  excelData:any;
  excel_data: any[];
  file: any;
  arrayBuffer: any;
  Form: FormGroup;
  Employee_name: any;
  datasuser: any;
  getrowdata: any;

  constructor(private http: HttpClient,private _formBuilder:FormBuilder, private route: ActivatedRoute, private router: Router,private toastr: ToastrService) {
    this.datas;
    this.Form = this._formBuilder.group({
      Empolyee_id: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.getUsers();
    this.getdatasite();
  }

  getUsers() {
    this.loading = true;
    this.http.post('https://dcsmaju.com.my/api/authentication/userlist', {}).subscribe((data: any) => {
      this.loading = false;
      this.datas = data.data[0].data;
      this.totalItems = this.datas.length;
      this.filterData();
    }, () => {
      this.loading = false;
    });
  }
  filterData() {
    this.filteredData = this.datas.filter(data =>
      !this.searchText ||
      (data.Name &&data.Name.toLowerCase().includes(this.searchText.toLowerCase())) ||
      (data.Designation && data.Designation.toLowerCase().includes(this.searchText.toLowerCase())) ||
      (data.Phone_number && data.Phone_number.includes(this.searchText)) ||
      (data.Empolyee_id&& data.Empolyee_id.toLowerCase().includes(this.searchText.toLowerCase()))||
      (data.client_siteDetail && data.client_siteDetail?.company_name.toLowerCase().includes(this.searchText.toLowerCase()))
    );
    this.totalItems = this.filteredData.length;
    this.paginate();
  } 


  paginate() {
    const startIndex = (this.advancedPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedData = this.filteredData.slice(startIndex, endIndex);
  }

  search() {
    this.advancedPage = 1;
    this.filterData();
  }

public view(event, item) {
    console.log(item);

    this.router.navigate(['main/viewdetails/' + item._id])
  }

  public edit(event, item) {
    console.log(item);
    this.router.navigate(['main/userdetails/' + item._id])
  }


  open($event, item){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.moral = (item._id);
    this.http.post(' https://dcsmaju.com.my/api/authentication/deleteuser', { userid: this.moral, }).subscribe(data => {
      console.log(data);
      this.toastr.success("User Deleted Successfull");
      this.ngOnInit();
    });
        Swal.fire(
          'Deleted!',
          'Your data has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  add() {
    this.router.navigate(['main/addusers'])
  }

  onFileChange(event) {
    this.excel_data = [];
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      
      // Convert the worksheet to JSON
      this.excel_data = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      console.log(this.excel_data);
  
      // Now, send the data to the server
      const requestData = { users: this.excel_data };
      this.http.post('https://dcsmaju.com.my/api/authentication/addusersbulk', requestData)
        .subscribe(data => {
          console.log(data);
          this.ngOnInit();
        });
    };
  
    // The following log will now be empty because the file reading hasn't finished yet
    // console.log(this.excel_data); 
  }
  


  assign() {
    console.log("this.Form", this.Form);

    if (this.Form.status === "VALID") {
      console.log("Testing");

      let Employee_datas = this.Form.value.Empolyee_id;
      console.log("Employee_datas", Employee_datas);

      let data = {
        Map_id: Employee_datas,
        Emp_id: this.getrowdata.Empolyee_id,
        Employee_name: "",
        gender: "",
        Client_place: "",
        Address: "",
        notification_title: ""
      };
      console.log("data", data);

      this.http.post('https://dcsmaju.com.my/api/mapTracking/addmapuser', { ...data }).subscribe((response: any) => {
        this.Employee_name = response.data.Name;
        
        this.Form.reset(); // Reset the form
        this.Form.markAsPristine();
        this.Form.markAsUntouched();
        this.Form.updateValueAndValidity();
        
        this.ngOnInit();

        if (this.Employee_name === "") {
          this.toastr.warning("retry later");
        } else {
          this.toastr.success("Employee map updated successfully");
        }
      }, error => {
        console.error('HTTP request error:', error);
        this.toastr.error("Error occurred while saving data");
      });
    } else {
      this.toastr.error("Fields Should not be Empty");
    }
  }

  getdatasite() {
  this.http.post('https://dcsmaju.com.my/api/client/sitelist',{}).subscribe((data:any) => {
    console.log("data",data.data);
    
    this.datasall = data.data[0];
    this.datasuser = this.datasall.data;
    console.log("this.datasuser",this.datasuser);
    
    console.log(this.datasall);
  });

}

send(element: any) {
  console.log("Row data:", element);
  this.getrowdata = element
}

}


class form1model {
  Email_id: "1";
}

