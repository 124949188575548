import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
