
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css']
})
export class GoogleMapComponent implements OnInit {


  @ViewChild('mapContainer', { static: false })
  gmap!: ElementRef;
  map!: google.maps.Map;
  datas: any;
  lat: number;
  lng: number;

  constructor(private activeRoute: ActivatedRoute) {
    this.activeRoute.params.subscribe(params => {
      const dataString = params['id'];
      const data = JSON.parse(dataString);
      console.log('Received data:', data);
      this.getCurrentLocation(data)
    });
  }

  ngOnInit(): void {

  }
  getCurrentLocation(data: any) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        console.log("this.lat", data.lat, "data.lng", data.lon);

        const coordinates = new google.maps.LatLng(data.lat as number, data.lon as number);


        const mapOptions: google.maps.MapOptions = {
          center: coordinates,
          zoom: 16,
        };

        // Move map initialization inside the callback
        this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);

        // Add marker after map is initialized
        const marker = new google.maps.Marker({
          position: coordinates,
          map: this.map,
        });

        console.log('Latitude:', this.lat);
        console.log('Longitude:', this.lng);
      }, (error) => {
        console.error('Error getting current location:', error);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }
}
