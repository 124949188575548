import { Component, OnInit } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-attendance-all',
  templateUrl: './attendance-all.component.html',
  styleUrls: ['./attendance-all.component.scss']
})
export class AttendanceAllComponent implements OnInit {
  datas: any[] = [];
  paginatedData: any[] = [];
  totalItems: number = 1;
  advancedPage: number = 1;
  pageSize: number = 10;
  loading: boolean = false;

  emp_id: string;
  start_date: string = "";
  end_date: string = "";
  date:any = "";
  datasss: any;
  selectedsite_id:any ;
  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router,private toastr: ToastrService) {

  }

  ngOnInit() {
    this.emp_id = localStorage.getItem('Empolyee_id');
   this.getdatas();
    this.http.post('https://dcsmaju.com.my/api/client/sitelist', {}).subscribe((data: any) => {
      this.datasss = data.data[0];
      console.log(this.datasss.data);
    });
  }


 getdatas() {
    this.loading = true;
    this.http.post('https://dcsmaju.com.my/api/Attendance/adminlist', {}).subscribe((data: any) => {
      console.log("data.data[0]",data.data);
      
      this.datas = data.data;  
      this.totalItems = data.data.length; 
      this.paginate();
      this.loading = false;
    });
  }

  paginate() {
    const startIndex = (this.advancedPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedData = this.datas.slice(startIndex, endIndex);
  }
  search() {
    console.log(this.date);
    if (this.date == "" && !this.selectedsite_id) {
      this.toastr.warning('Please Enter the Date Or Select Site');
    } else {
      let data = {
        date: this.date?this.date:"",
        site_id:""
      }
      if (this.selectedsite_id) {
        data.site_id = this.selectedsite_id
      }
      this.http.post('https://dcsmaju.com.my/api/Attendance/adminlist', { ...data }).subscribe((data: any) => {
        this.datas = data.data;
        this.totalItems = data.data.length; 
        console.log("this.datas", this.datas,this.totalItems);
        this.paginate();
        this.loading = false;
      });
    }
  }
  newsync() {
    this.getdatas();
    this.date ="",
    this.selectedsite_id=""
  }

  addapi1() {
    this.router.navigate(['main/Attendanceall'])
  }


  addapi2() {
    this.router.navigate(['main/Attendancedate'])
  }

  addapi3() {
    this.router.navigate(['main/Attendanceweekly'])
  }

  addapi4() {
    this.router.navigate(['main/AttendanceMonthly'])
  }

  addapi5() {
    this.router.navigate(['main/Attendanceyearly'])
  }

  addapi6() {
    this.router.navigate(['main/Attendancecustom'])
  }

  view(data) {
    const dataString = JSON.stringify(data);
    console.log(dataString);
    this.router.navigate(['/main/AttendenceMap', { id: JSON.stringify(data) }])
  }





}
