import { Component, OnInit } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router'

import { retry } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-pointtrack',
  templateUrl: './pointtrack.component.html',
  styleUrls: ['./pointtrack.component.scss']
})
export class PointtrackComponent implements OnInit {


  Employee_data: any;
  Employee_name: string;
  Employee_id: string;
  create_date: any;
  update_date: any;
  model: form1model;
  mappoint_id: string;
  Points: any;
  Site_data: any;
  datasall: any;
  datasuser: any;
  date = "";
  selectedsite_id: any
  datasss: any;
  datas: any[] = [];
  filteredData: any[] = [];
  paginatedData: any[] = [];
  totalItems = 1;
  advancedPage = 1;
  pageSize = 10;
  loading: boolean = false;
  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private Toastr :ToastrService) {
    this.model = new form1model();
    this.Points = [];
    this.Employee_data = [];
  }

  Form = new FormGroup({
    site_id: new FormControl(),
    title: new FormControl(''),
    description: new FormControl('')
  });
  ngOnInit() {

    this.http.post(' https://dcsmaju.com.my/api/client/sitelist', {}).subscribe((data: any) => {
      this.datasss = data.data[0];
      console.log(this.datasss.data);
    });
    this.getlistpoints();

  }
  downloadQRCode(data:any) {
    const downloadLink = document.createElement('a');
    downloadLink.href = data.qrcode;
    downloadLink.download = `${data.title}QRcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    this.Toastr.success("QRCode download Successfully")
  }


  assign() {
    console.log("this.Form", this.Form);
  
    if (this.Form.value.description !== "" || this.Form.value.site_id !== null || this.Form.value.title  !== "") {
      console.log("Testing");

      let siteData = this.Form.value.site_id

      let data = {
        site_id: siteData,
        site_name: "",
        Employee_id: '',
        Employee_Name: "",
        createdtime: "",
        description: this.Form.value.description,
        title: this.Form.value.title,
        updatedtime: "",
        lat1: 0,
        lng1: 0,

      }

      this.http.post(' https://dcsmaju.com.my/api/PointTracking/Addpoints', { ...data }).subscribe((data: any) => {
        this.Employee_name = data.data.Name;
        var stringForm = data.data.id.toString();
        this.Employee_id = stringForm;
        console.log(this.Employee_name);
        if (this.Employee_name === "") {
          this.Toastr.warning("retry later");
        } else {
          this.Toastr.warning(data.message);
        }
      });
    }
    else {
      this.Toastr.error("Fields Should not be Empty");
    }



  }

  assign2(Employee_id, employees, titles, Descriptions) {
    var date = new Date();

    this.create_date = formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    this.update_date = formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    this.http.post(' https://dcsmaju.com.my/api/PointTracking/Addpoints', { "Emp_id": Employee_id, "Employee_Name": employees, "createdtime": this.create_date, "description": Descriptions, "title": titles, "updatedtime": this.update_date, "status": "Open" }).subscribe((data: any) => {
      this.Toastr.success("Added Successfully");
      this.ngOnInit();
    });
  }


  edit(event, data) {
    var stringForm = data.ukey.toString();
    this.mappoint_id = stringForm;
    this.create_date = data.created_date;
    this.http.post(' https://dcsmaju.com.my/api/PointTracking/fetchpoints', { "ukey": this.mappoint_id }).subscribe((data: any) => {
      this.Points = data.data[0];
      console.log(this.Points.Employee_id);
    });
  }


  delete(event, data) {
    var stringForm = data.ukey.toString();
    this.mappoint_id = stringForm;
    this.http.post(' https://dcsmaju.com.my/api/PointTracking/deletepoints', { "ukey": this.mappoint_id }).subscribe((data: any) => {
      this.Toastr.success("Point Deleted Successfully");
      this.ngOnInit();
    });
  }

  assign1(employee, title, Description, status) {

    this.update_date = formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    this.http.post(' https://dcsmaju.com.my/api/PointTracking/pointsupdate', { "Employee_Name": employee, "description": Description, "title": title, "updatedtime": this.update_date, "status": status, "ukey": this.mappoint_id }).subscribe((data: any) => {
      this.Toastr.success("Updated Successfully");
      this.ngOnInit();
    });
  }


  view($event, data) {
    this.router.navigate(["main/Pointtrackingdetails/" + data.ukey])
  }


  history($event, data) {
    this.router.navigate(["main/PointTrackRecordslist/" + data.ukey])
  }


  spot($event, data) {
    this.router.navigate(["main/PointTrackMapSpots/" + data.ukey])
  }
  search() {
    console.log("this.selectedsite_id ", this.selectedsite_id);
    if (!this.selectedsite_id) {
      this.Toastr.error("Please select Site...")
    }
    let data = {
      site_id: this.selectedsite_id,
      date: this.date
    };
  
    console.log("data", data);
  
    this.http.post('https://dcsmaju.com.my/api/PointTracking/pointslist', data, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token') 
      }
    }).subscribe((response: any) => {
      if (response.status === 401) {
        console.error("Unauthorized: ", response.message);
      } else {
        this.datas = response.data[0].data;
        this.totalItems = response.data[0].pagination[0].totalCount; 
        console.log(this.datas,this.totalItems);
        this.paginate();
      }
    }, error => {
      this.Toastr.error("An error occurred: ", error);
      console.error("An error occurred: ", error);
    });
  }

  getlistpoints() {
    let data = {
      site_id: this.selectedsite_id,
      date: this.date
    };
  
    console.log("data", data);
  
    this.http.post('https://dcsmaju.com.my/api/PointTracking/pointslist', data, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token') 
      }
    }).subscribe((response: any) => {
      if (response.status === 401) {
        console.error("Unauthorized: ", response.message);
      } else {
        this.datas = response.data[0].data;
        this.totalItems = response.data[0].pagination[0].totalCount; 
        console.log(this.datas,this.totalItems);
        this.paginate();
      }
    }, error => {
      this.Toastr.error("An error occurred: ", error);
      console.error("An error occurred: ", error);
    });
  }
  paginate() {
    const startIndex = (this.advancedPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedData = this.datas.slice(startIndex, endIndex);
  }
  send(){

  }

  deletePonit(data){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        console.log("data",data);
        
        this.http.post(' https://dcsmaju.com.my/api/PointTracking/deletepoints', {'ukey': data._id}).subscribe(( data: any) => {
          this.ngOnInit();
          this.search();
      });
        Swal.fire(
          'Deleted!',
          'Your data has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
}




class form1model {
  Email_id: "1";
}
