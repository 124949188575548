import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questionpaper',
  templateUrl: './questionpaper.component.html',
  styleUrls: ['./questionpaper.component.scss']
})
export class QuestionpaperComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  assign(Question,Option_1,Option_2,Option_3,Option_4,correct_answer){
    
  }

}
