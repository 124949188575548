import { Component, OnInit, Inject } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';

@Component({
  selector: 'app-notification-readed',
  templateUrl: './notification-readed.component.html',
  styleUrls: ['./notification-readed.component.scss']
})
export class NotificationReadedComponent implements OnInit {


  datas: any;
  datass: any

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private http: HttpClient, private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit() {
    let id = localStorage.getItem('user_id');
    let count = localStorage.getItem('notificount');
    this.http.post(' https://dcsmaju.com.my/api/notification/listofnotification', { "user_id": "" + id }).subscribe((data: any) => {
      this.datass = data.data;
      console.log(this.datass.length)
      this.datas = []
      for (let i = 0; i < this.datass.length; i++) {
        console.log(this.datass[i].status)
        if (this.datass[i].status == "Readed") {
          this.datas.push(this.datass[i])
        }
      }
      console.log(this.datas);
    });
  }


  saveInLocal(key, val): void {
    console.log('recieved= key:' + key + 'value:' + val);
    this.storage.set(key, val);
  }
  getFromLocal(key): any {
    console.log('recieved= key:' + key);
    return this.storage.get(key);
  }

  view(data) {
    localStorage.setItem('nofifcationdata', data);
    this.router.navigate(['main/viewnotification'])
  }


}