import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-supervisor-tracking-map',
  templateUrl: './supervisor-tracking-map.component.html',
  styleUrls: ['./supervisor-tracking-map.component.css']
})
export class SupervisorTrackingMapComponent implements OnInit {


 
  Employee_data: any;
  Employee_name: string;
  Employee_id: string;
  create_date: any;
  update_date: any;
  model: form1model;
  mappoint_id: string;
  Points: any;
  Site_data: any;
  datasall: any;
  datasuser: any;
  date : any;
  datasss: any;
  datas: any[] = [];
  paginatedData: any[] = [];
  totalItems = 1;
  advancedPage = 1;
  pageSize = 10;
  loading: Boolean = false;
  selectedsite_id: any;

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private _formBuilder: FormBuilder) {
    this.model = new form1model();
    this.Points = [];
    this.Employee_data = [];
  }

  Form = this._formBuilder.group({
    site_id: new FormControl(""),
    Employee_id: new FormControl(),
    title: new FormControl(''),
    description: new FormControl('')
  });
  ngOnInit() {

    this.getpoints()

    this.http.post(' https://dcsmaju.com.my/api/client/sitelist', {}).subscribe((data: any) => {
      this.datasss = data.data[0];
      console.log(this.datasss.data);
    });
    
  }
  getpoints() {
    this.loading = true;
    this.http.post('https://dcsmaju.com.my/api/mapTracking/PointTrackMapSpotlist', {}).subscribe((data: any) => {
      this.datas = data.data[0].data;
      this.totalItems = data.data[0].pagination[0].totalCount;
      this.paginate();
      this.loading = false;
    });
  }

  search() {
    const data = {
      site_id: this.selectedsite_id,
      date: this.date
    };
    console.log("data", data);

    this.loading = true;
    this.http.post('https://dcsmaju.com.my/api/mapTracking/PointTrackMapSpotlist', data).subscribe((response: any) => {
      this.datas = response.data[0].data;
      this.totalItems = response.data[0]?.pagination[0]?.totalCount;
      console.log("this.totalItems", this.totalItems);

      this.paginate();
      this.loading = false;
    });
  }

  paginate() {
    const startIndex = (this.advancedPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedData = this.datas.slice(startIndex, endIndex);
  }

  assign() {
    console.log("this.Form", this.Form);

    if (this.Form.status == "VALID") {
      console.log("Testing");

      let siteData = this.Form.value.site_id
      let Employee_datas = this.Form.value.Employee_id

      let data = {
        site_id: siteData,
        site_name: "",
        Employee_id: Employee_datas,
        Employee_Name: "",
        createdtime: "",
        description: this.Form.value.description,
        title: this.Form.value.title,
        updatedtime: "",
        lat1: 0,
        lng1: 0,

      }

      this.http.post(' https://dcsmaju.com.my/api/PointTracking/Addpoints', { data }).subscribe((data: any) => {
        this.Employee_name = data.data.Name;
        var stringForm = data.data.id.toString();
        this.Employee_id = stringForm;
        console.log(this.Employee_name);
        if (this.Employee_name === "") {
          alert("retry later");
        } else {
          alert("Error");
        }
      });
    }
    else {
      alert("Fields Should not be Empty");
    }



  }

  assign2(Employee_id, employees, titles, Descriptions) {
    var date = new Date();

    this.create_date = formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    this.update_date = formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    this.http.post(' https://dcsmaju.com.my/api/PointTracking/Addpoints', { "Emp_id": Employee_id, "Employee_Name": employees, "createdtime": this.create_date, "description": Descriptions, "title": titles, "updatedtime": this.update_date, "status": "Open" }).subscribe((data: any) => {
      alert("Added Successfully");
      this.ngOnInit();
    });
  }


  edit(event, data) {
    var stringForm = data.ukey.toString();
    this.mappoint_id = stringForm;
    this.create_date = data.created_date;
    this.http.post(' https://dcsmaju.com.my/api/PointTracking/fetchpoints', { "ukey": this.mappoint_id }).subscribe((data: any) => {
      this.Points = data.data[0];
      console.log(this.Points.Employee_id);
    });
  }


  delete(event, data) {
    var stringForm = data.ukey.toString();
    this.mappoint_id = stringForm;
    this.http.post(' https://dcsmaju.com.my/api/PointTracking/deletepoints', { "ukey": this.mappoint_id }).subscribe((data: any) => {
      alert("Deleted Successfully");
      this.ngOnInit();
    });
  }

  assign1(employee, title, Description, status) {

    this.update_date = formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    this.http.post(' https://dcsmaju.com.my/api/PointTracking/pointsupdate', { "Employee_Name": employee, "description": Description, "title": title, "updatedtime": this.update_date, "status": status, "ukey": this.mappoint_id }).subscribe((data: any) => {
      alert("Updated Successfully");
      this.ngOnInit();
    });
  }


  view($event, data) {
    this.router.navigate(["main/Pointtrackingdetails/" + data.ukey])
  }


  history($event, data) {
    this.router.navigate(["main/PointTrackRecordslist/" + data.ukey])
  }


  spot($event, data) {
    this.router.navigate(["main/PointTrackMapSpots/" + data.ukey])
  }
  
}




class form1model {
  Email_id: "1";
}



