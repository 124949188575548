import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';

@Component({
  selector: 'app-shift-meeting',
  templateUrl: './shift-meeting.component.html',
  styleUrls: ['./shift-meeting.component.css']
})
export class ShiftMeetingComponent implements OnInit {

  Site_data: any;
  sitelistdata: any;
  selectedsite_id: any
  datasss: any;
  date: any = "";
  datas: any[] = [];
  paginatedData: any[] = [];
  totalItems: number = 1;
  advancedPage: number = 1;
  pageSize: number = 10;
  loading: boolean = false;
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private http: HttpClient, private route: ActivatedRoute, private router: Router) {
  }
  ngOnInit() {
  this.getshiftmeeting();

    this.http.post(' https://dcsmaju.com.my/api/client/sitelist', {}).subscribe((data: any) => {
      this.datasss = data.data[0];
      console.log(this.datasss.data);
    });
  }

  getshiftmeeting() {
    this.http.post(' https://dcsmaju.com.my/api/shiftmeeting/list', {}).subscribe((data: any) => {
      console.log("data", data);

      this.datas = data.data;
      this.totalItems = data.data.length;
      this.paginate();

    });
  }
  search() {
    console.log("this.selectedsite_id ", this.selectedsite_id);
    let data = {
      siteId: this.selectedsite_id,
      date: this.date
    }
    this.http.post(' https://dcsmaju.com.my/api/shiftmeeting/list', { ...data }).subscribe((data: any) => {
      this.datas = data.data;
      this.totalItems = data.data.length;
      this.paginate();
      console.log(this.datas);
    });
  }

  paginate() {
    const startIndex = (this.advancedPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedData = this.datas.slice(startIndex, endIndex);
  }
  view(data) {
    const dataString = JSON.stringify(data); // Convert object to string
    console.log(dataString); // Log the serialized object
    // localStorage.setItem('employeetrackdetail', dataString);
    // this.router.navigate({['main/GoogleMap/'], { id: dataString }}); // Pass as a route parameter
    this.router.navigate(['/main/GoogleMap', { id: JSON.stringify(data) }])
  }


  delete(data) {
    this.http.post(' https://dcsmaju.com.my/api/employee_tracking/deleteEmployeeTracking', { 'id': data.id }).subscribe((data: any) => {
      alert('Employee tracking deleted successfully');
      this.ngOnInit();
    });
  }
  saveInLocal(key, val): void {
    console.log('recieved= key:' + key + 'value:' + val);
    this.storage.set(key, val);
  }
  getFromLocal(key): any {
    console.log('recieved= key:' + key);
    return this.storage.get(key);
}



}
// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
