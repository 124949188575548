import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss']
})
export class AdduserComponent {
  titles = [];
  datas: any;
  model1: form1model;
  adduser: Adduser;
  excelData:any;

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private http: HttpClient, private route: ActivatedRoute, private router: Router,
private toastr:ToastrService) {

    this.adduser = new Adduser();
  }

  addapi() {
    console.log(this.adduser.Email_id)
    if (this.adduser.Name == undefined ) {
      this.toastr.warning("Name Field Should not be Empty");
    } else if (this.adduser.Designation == undefined) {
      this.toastr.warning("Designation Field Should not be Empty");
    } else if (this.adduser.Level == undefined) {
      this.toastr.warning("Level Field Should not be Empty");
    } else if (this.adduser.Phone_number == undefined) {
      this.toastr.warning("Phone Number Field Should not be Empty");
    } else if (this.adduser.Email_id == undefined) {
      this.toastr.warning("Login ID Field Should not be Empty");
    } else if (this.adduser.Password == undefined) {
      this.toastr.warning("Password Field Should not be Empty");
    } else if (this.adduser.Name == '') {
      this.toastr.warning("Name Field Should not be Empty");
    } else if (this.adduser.Designation == '') {
      this.toastr.warning("Designation Field Should not be Empty");
    } else if (this.adduser.Level == '') {
      this.toastr.warning("Level Field Should not be Empty");
    } else if (this.adduser.Phone_number == '') {
      this.toastr.warning("Phone Number Field Should not be Empty");
    } else if (this.adduser.Email_id == '') {
      this.toastr.warning("Login ID Field Should not be Empty");
    } else if (this.adduser.Password == '') {
      this.toastr.warning("Password Field Should not be Empty");
    } else if (this.adduser.Password.length < 4) {
      this.toastr.warning("Password Must be Above 4 character");
    } else {
      console.log(this.adduser);
      let id = localStorage.getItem('user_id');
      let name = localStorage.getItem('Name');
      this.adduser.Add_by = "Name: " + name + ",User-id: " + id;
      let formattedPhoneNumber: string = this.adduser.Phone_number.toString().padStart(10, '0');

      this.adduser.Phone_number = formattedPhoneNumber;
    
      this.http.post('https://dcsmaju.com.my/api/authentication/addusers', this.adduser).pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Error occurred:', error);
      
          if (error.status !== 200 && error.error.data.message) {
            this.toastr.warning(error.error.data.message);
          } else {
            this.toastr.error('An error occurred while adding the user. Please try again later.');
          }
      
          return throwError(error);
        })
      ).subscribe(data => {
        console.log("data", data);
      
        this.datas = data;
        console.log(this.datas);
        if (this.datas.code !== 200) {
          this.toastr.warning(this.datas.message);
        } else {
          this.toastr.success("Added Successfully");
          this.router.navigate(['main/viewuser']);
        }
      });
    }










  }
  addusers(){

  }

  getFromLocal(key): any {
    // console.log('recieved= key:' + key);
    return this.storage.get(key);
  }
}
class form1model {
  name: string;
  mobile_number: string;
  email_id: string;
  password: string;
}


class Adduser {
  Employee_ID: string;
  Name: string;
  Designation: string;
  Level: string;
  Phone_number: string;
  Email_id: string;
  Password: string;
  Empolyee_id: string;
  mobileUser: Boolean = true;
  Add_by: string;
}