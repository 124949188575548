import { Component, OnInit } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';


import { EmployeeService } from '../../services/employee.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-clientsite',
  templateUrl: './clientsite.component.html',
  styleUrls: ['./clientsite.component.scss']
})
export class ClientsiteComponent implements OnInit {

  titles = [];
  datas: any;
  cliid: string;
  model: form1model;
  employee_id: string;


  selectedfile: any;

  displayedColumns: string[] = ['S_No','Site_Name', 'Address', 'Contact_Number', 'View', 'Edit', 'Delete', 'Map Employee'];
  dataSource: MatTableDataSource<any>;
  datasss: any[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  Employee_name: any;
  datasall: any;
  datasuser: any;
  getrowdata: any;
  Form: FormGroup;
  companylist: any;

  constructor(private userService: EmployeeService, private http: HttpClient, private route: ActivatedRoute, private router: Router, private _formBuilder: FormBuilder,
    private toastr:ToastrService
  ) {
    this.model = new form1model();
    this.Form = this._formBuilder.group({
      Empolyee_id: ['', Validators.required]
    });
  }


  ngOnInit() { 
this.getdatasite();

    this.http.get(' https://dcsmaju.com.my/api/filterduserlist').subscribe(data => {
      this.datasall = data;
      this.datasuser = this.datasall.data
      console.log(this.datasall);
    });

  }

  getdatasite() {
    this.http.post('https://dcsmaju.com.my/api/client/sitelist', {}).subscribe((data: any) => {
      this.datasss = data.data;
      console.log("this.datasss", this.datasss);
      
      this.dataSource = new MatTableDataSource(this.datasss[0].data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      // Optional: if you want to log the total count for any reason
      const totalCount = this.datasss[0].pagination[0].totalCount;
      console.log("total count", totalCount);
    });
  }
  getSerialNumber(index: number): number {
    return (this.paginator.pageIndex * this.paginator.pageSize) + index + 1;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
 
  assign() {
    console.log("this.Form", this.Form);

    if (this.Form.status === "VALID") {
      console.log("Testing");

      let Employee_datas = this.Form.value.Empolyee_id;
      console.log("Employee_datas", Employee_datas);

      let data = {
        Map_id: this.getrowdata._id,
        Emp_id: Employee_datas,
        Employee_name: "",
        gender: "",
        Client_place: "",
        Address: "",
        notification_title: ""
      };
      console.log("data", data);

      this.http.post('https://dcsmaju.com.my/api/mapTracking/addmapuser', { ...data }).subscribe((response: any) => {
        this.Employee_name = response.data.Name;
        
        this.Form.reset(); // Reset the form
        this.Form.markAsPristine();
        this.Form.markAsUntouched();
        this.Form.updateValueAndValidity();
        
        this.ngOnInit();

        if (this.Employee_name === "") {
          this.toastr.warning("retry later");
        } else {
          this.toastr.success("Employee map updated successfully");
        }
      }, error => {
        console.error('HTTP request error:', error);
        this.toastr.error("Error occurred while saving data");
      });
    } else {
      this.toastr.error("Fields Should not be Empty");
    }
  }

  find(data) {
    console.log(data)
    this.getUnitList(data);
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }



  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getUnitList(name): any {
    this.userService.getUnitList(name)
      .subscribe(
        response => {
          this.dataSource.data = response.data as User[];
          console.log(this.dataSource.data)
        },
        errorResponse => {
          console.log(errorResponse.error.error);
        });
  }

  public attach() {
    const fd = new FormData();
    fd.append('filetoupload', this.selectedfile, this.selectedfile.name);
    console.log(fd);
    this.http.post(' https://dcsmaju.com.my/api/client/addclientbulk', fd)
      .subscribe((data: any) => {
        console.log(data.data);
      });
  }

  onfileselected(event) {
    console.log(event);
    this.selectedfile = event.target.files[0];
  }

  public view(event, item) {
    console.log(item._id);
    this.router.navigate(['main/viewclientsite/' + item._id])
  }

  public edit(event, item) {
    this.router.navigate(['main/editclientsite/' + item._id])
  }


  public payment(event, item) {
    console.log(item.id);
    this.router.navigate(['main/paymentpage/' + item.id])
  }


  public attachment(event, item) {
    console.log(item.id);
    this.router.navigate(['main/clientattachment/' + item.id])
  }

  public requiredment(event, item) {
    console.log(item.id);
    this.router.navigate(['main/addrequirement/' + item.id])
  }



  public trainingcheck(event, item) {
    console.log(item.id);
    this.router.navigate(['main/TrainingReoprt/' + item.id])
  }
  public qualitycheck(event, item) {
    console.log(item.id);
    this.router.navigate(['main/qualitycheck/' + item.id])
  }
  public nightcheck(event, item) {
    console.log(item.id);
    this.router.navigate(['main/nightreport/' + item.id])
  }




  // public delete($event, item) {
  //   console.log("item", item);

  //   console.log(item._id);
  //   // return false;
  //   this.http.post(' https://dcsmaju.com.my/api/client/deletclientsite', { id: item._id }).subscribe(data => {
  //     console.log(data);
  //     alert("Deleted Successfully");
  //     this.ngOnInit();
  //   });
  // }


  mapuserlist(data: any): Observable<any> {
    return this.http.post('https://dcsmaju.com.my/api/mapTracking/addmapuserlist', { Map_id: data }, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).pipe(
      map((response: any) => {
        if (response.status === 401) {
          console.error("Unauthorized: ", response.message);
          // Handle unauthorized access error here (e.g., redirect to login)
          throw new Error("Unauthorized");
        } else {
          this.datasall = response;
          console.log("this.client_id", this.datasall);

          // Assuming the response contains data array
          if (this.datasall.data && this.datasall.data.length > 0) {
            return this.datasall.data[0].data;
          } else {
            console.error("No data found in response.");
            // Handle no data found scenario
          }
        }
      }),
      catchError((error) => {
        console.error("An error occurred: ", error);
        // Handle other errors here
        throw error;
      })
    );
  }

  delete(event: any, item: any) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        console.log("item2", item);
        this.mapuserlist(item._id).subscribe(
          (datas: any) => {
            console.log("datas", datas);
            if (datas) {
              datas.forEach(element => {
                let updateData = {
                  Map_id: element.Map_id,
                  Emp_id: element.Emp_id,
                  status: "Open"
                };
                this.http.post('https://dcsmaju.com.my/api/updateuserstatus', updateData).subscribe((data: any) => {
                  this.companylist = data.data;
                });
              });
            }

            this.http.post('https://dcsmaju.com.my/api/client/deletclientsite', { id: item._id }).subscribe(data => {
              console.log(data);
              this.ngOnInit();
              Swal.fire(
                'Deleted!',
                'Your data has been deleted.',
                'success'
              );
            });
          },
          (error) => {
            console.error("An error occurred while fetching map user list: ", error);
            Swal.fire(
              'Error',
              'An error occurred while deleting the data.',
              'error'
            );
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        );
      }
    });
  }

  enable($event, item) {
    if (item.status == "enable") {
      let a = "disable";
      this.http.post(' https://dcsmaju.com.my/api/client/sitestatus', { "id": "" + item.id, "status": a }).subscribe(data => {
        console.log(data);
        alert("Status Updated");
        this.ngOnInit();
      });
    } else {
      let a = "enable";
      this.http.post(' https://dcsmaju.com.my/api/client/sitestatus', { "id": "" + item.id, "status": a }).subscribe(data => {
        console.log(data);
        alert("Status Updated");
        this.ngOnInit();
      });
    }
  }

  addclients() {
    this.router.navigate(['main/addclientsite'])
  }


  send(element: any) {
    console.log("Row data:", element);
    this.getrowdata = element
  }

}


class form1model {
  Email_id: "1";
}


export interface User {

}

